<template>
  <div class="leave-message" ref="footer">
    <!-- 输入 -->
    <van-field
      v-model="message"
      type="textarea"
      :autosize="{ maxHeight: 105 }"
      rows="1"
      :border="false"
      placeholder="请输入..."
      @keypress.enter.prevent="replyTxt"
    >
      <template #label></template>
      <template #extra>
        <div class="button-send is-loading" @click="replyTxt"></div>
      </template>
    </van-field>
    <!-- 操作 -->
    <div class="input-extra">
      <!-- 上传图片 -->
      <van-uploader :before-read="validFile" :after-read="uplodadFile">
        <div class="button-icon button-image"></div>
      </van-uploader>
      <!-- 收起键盘 -->
      <div class="button-icon button-hold"></div>
    </div>
  </div>
</template>

<script>
/**
 * 留言回复专用
 */
import { Field, Uploader, Notify } from 'vant'
import { replyComment } from '@/api/comments.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Inputer',
  components: {
    [Field.name]: Field,
    [Uploader.name]: Uploader
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      message: ''
    }
  },
  computed: {
    ...mapGetters(['userData'])
  },
  watch: {
    message () {
      setTimeout(this.initLayout, 90)
    }
  },
  mounted () {
    this.initLayout()
  },
  activated () {
    this.initLayout()
  },
  methods: {
    /**
     * 初始化回复留言的布局
     */
    initLayout () {
      this.$store.commit('updateLayout', {
        footerHeight: this.$refs.footer.offsetHeight
      })
    },
    /**
     * 回复文字消息
     */
    replyTxt () {
      /**
       * 验证输入内容的有效性
       */
      if (!this.message || !this.message.trim()) {
        return false
      }
      replyComment({
        commentsId: this.id,
        source: '4',
        userId: this.userData.userId,
        comments: this.message,
        files: null
      }).then(() => {
        // 清空用户输入的内容
        this.message = ''
        this.$emit('replySuccess')
      })
    },
    /**
     * 文件上传之前检验格式
     */
    validFile (file) {
      const { name, size } = file
      console.log('当前选择的图片体积是： ' + size)
      // 体积验证
      if (size > 5 * 1024 * 1024) {
        // (1M = 1024 * 1024 byte)
        Notify({
          type: 'danger',
          message: '图片体积过大'
        })
        return false
      }
      if (!/.(png|jpg|jpeg)$/.test(name.toLowerCase())) {
        Notify({
          type: 'danger',
          message: '请上传格式为 png、jpg、jpeg 的图片'
        })
        return false
      }
      return true
    },
    /**
     * 图片选择完直接发送
     */
    uplodadFile (file) {
      replyComment({
        commentsId: this.id,
        source: '4',
        userId: this.userData.userId,
        comments: '',
        files: file.file
      }).then(() => {
        this.$emit('replySuccess')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.leave-message {
  margin: 0 10px;
  padding: 12px 12px 15px 12px;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  .button-send {
    position: relative;
    z-index: 10;
    top: -4px;
    width: 48px;
    height: 48px;
    background: url('../../../assets/icons/icon-send-b.png') no-repeat center
      center;
    background-size: 48px;
    background-color: #00ae7e;
    border-radius: 48px;
    margin-left: 20px;
  }
  .van-cell {
    padding: 0 0 0 0;
    line-height: initial;
    align-items: flex-end;
  }
  .input-extra {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .button-icon {
      width: 100px;
      height: 50px;
      line-height: 50px;
      &.button-image {
        background: url('../../../assets/icons/upload-image-mini.png') no-repeat
          center center;
        background-size: 30px;
        border-right: 1px solid #f5f5f5;
      }
      &.button-hold {
        background: url('../../../assets/icons/keyboard-hold.jpg') no-repeat
          center center;
        background-size: 40px;
        border-left: 1px solid #f5f5f5;
      }
    }
  }
}
::v-deep {
  .van-field__value {
    background-color: #ebebeb;
    border-radius: 30px;
    padding-left: 40px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 40px;
  }
}
</style>
